export const environment = {
  production: false,

  pwaFirebaseConfig: JSON.parse('{"apiKey":"AIzaSyBjWACIZnhYbbp41MOsiZr8HXsJn_eAfNo","authDomain":"tu-dortmund-app-beta.firebaseapp.com","databaseURL":"https://tu-dortmund-app-beta.firebaseio.com","projectId":"tu-dortmund-app-beta","storageBucket":"tu-dortmund-app-beta.appspot.com","messagingSenderId":"774824629127","appId":"1:774824629127:web:4f0f81bc0531ed5a5af922"}'),
  pwaFirebaseKey: "BE6BO_8ruo7abXeByi1HlNQzyr0zu2xySaMLN5yq0gOAfS4Oyb0NeED_yHN3vmLole7CeL9T71FLUEkJLC1c6GI",

  // apiUrl: 'http://172.16.0.62:8080/staging',
  // apiUrl: 'http://localhost:8100/prod',
  // apiUrl: 'https://mobil.itmc.tu-dortmund.de',
  apiUrl: 'https://mobil.itmc.tu-dortmund.de/staging',

  katalogPlusUrl: 'https://katalog.ub.tu-dortmund.de/search?q=',

  tiles: {
    home: [
      { id: 'news' },
      { id: 'canteen' },
      { id: 'events' },
      { id: 'lsf' },
      { id: 'employee-search' },
      { id: 'campus-navi' },
      { id: 'study-areas'},
      { id: 'departure-monitor' },
      { id: 'hochschulsport', url: 'https://www.buchsys.ahs.tu-dortmund.de/sportarten/aktueller_zeitraum/m.html' },
      { id: 'asta', url: 'https://service.asta-dortmund.de/app' },
      { id: 'feedback' },
      { id: 'emergency' }
    ],
    personal: [
      {
        id: 'campus-id/employee',
        iconName: 'campus-id-e',
        titleKey: 'campus-id.employee.title',
        access_roles: ['employee'],
        visible_roles: ['employee'],
      },
      {
        id: 'campus-id/student',
        iconName: 'campus-id-s',
        titleKey: 'campus-id.student.title',
        access_roles: ['student'],
        visible_roles: ['student'],
      },
      { id: 'timetable', access_roles: ['student'] },
      { id: 'ticket', access_roles: ['student'] },
      { id: 'exams', access_roles: ['student'] },
      {
        id: 'ub',
        access_roles: ['student', 'employee', 'extusers@library.tu-dortmund.de'],
        subTiles: [
          {
            id: 'pass',
            access_roles: ['student', 'employee', 'extusers@library.tu-dortmund.de'],
            iconName: 'ub-pass',
            titleKey: 'ub-pass.title',
          },
          {
            id: 'account',
            access_roles: ['student', 'employee', 'extusers@library.tu-dortmund.de'],
            iconName: 'ub-account',
            titleKey: 'ub-account.title',
          },
          {
            id: 'vscout',
            access_roles: ['student', 'employee', 'extusers@library.tu-dortmund.de'],
            url: 'https://scout-efb.ub.tu-dortmund.de',
          },
        ],
      },
      { id: 'canteen-coupon', visible_roles: ['AZUBI'], access_roles: ['AZUBI']},
      { id: 'employee-profile', access_roles: ['employee'], modal: true },
      { id: 'wlan-password', access_roles: ['member'] }
    ],
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/plugins/zone-error';
