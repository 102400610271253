import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NGSWBypassInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const bypassedRequest = request.clone({ 
      headers: request.headers.set('ngsw-bypass', ''),
    });

    return next.handle(bypassedRequest);
  }
}
